import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '@features/auth';
import { filter } from 'rxjs';

@Component({
  selector: 'ds-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['../shared.scss', './signout.component.scss'],
  standalone: false,
})
export class DsSignoutComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit() {
    this.userService.logoutLocally();
    this.dialog.closeAll();

    // Needed for mobile apps. Otherwise, the app will stay on the signout page after re-logging in.
    this.userService.isAuthorized$
      .pipe(
        filter((isAuthorized) => isAuthorized),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }

  relogin() {
    this.userService.login();
  }
}
